<template>
  <b-row>
    <b-col
      lg="12"
    >
      <b-card>
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col md="6">
              <select-language :selected-language="productsForm" />
              <p
                class="text-danger"
              >
                {{ ValidationErrors.language }}
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Title"
                rules="required"
              >
                <b-form-group
                  label="Title"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="productsForm.title"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Title"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="Excerpt"
                rules="required"
              >
                <b-form-group
                  label="Excerpt"
                  label-for="desc"
                >
                  <b-form-textarea
                    id="desc"
                    v-model="productsForm.exerpt"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Excerpt"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                rules="required"
                name="Description"
              >
                <b-form-group
                  :state="getValidationState(validationContext)"
                  label="Descripption"
                  label-for="blog-content"
                  class="mb-2"
                >
                  <quill-editor
                    id="blog-content"
                    v-model="productsForm.description"
                    :options="snowOption"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                rules="required"
                name="cat"
              >
                <b-form-group
                  label="Categories"
                  label-for="cat"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="productsForm.productcategory"
                    label="name"
                    multiple
                    :reduce="cat => cat.id"
                    :options="categoriesList"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                rules="required"
                name="cert"
              >
                <b-form-group
                  label="Certifications"
                  label-for="cert"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="productsForm.certification"
                    label="name"
                    multiple
                    :options="certificationList"
                    :reduce="cert => cert.id"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              class="mb-2"
            >
              <div class="border rounded p-2">
                <h4 class="mb-1">
                  Featured Image
                </h4>
                <b-media
                  no-body
                  vertical-align="center"
                  class="flex-column flex-md-row"
                >
                  <b-media-aside>
                    <b-img
                      :src="preview"
                      height="200"
                      width="200"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-media-body>
                    <div class="d-inline-block">
                      <b-form-file
                        v-model="featuredImage.image"
                        accept=".jpg, .png"
                        placeholder="Choose file"
                        @change="onChange"
                      />
                    </div>
                  </b-media-body>
                </b-media>
              </div>
            </b-col>
            <b-col
              cols="12"
              class="mt-50"
            >
              <b-button
                v-if="!loader"
                variant="primary"
                class="mr-1"
                @click="addProduct"
              >
                Add
              </b-button>
              <b-button
                v-if="loader"
                variant="primary"
                disabled
                class="mr-1"
              >
                <b-spinner
                  small
                  type="grow"
                />
                Loading...
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  computed,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { quillEditor } from 'vue-quill-editor'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import store from '@/store'
import handleFormData from '@/common/compositions/handleFormData'
import selectLanguage from '@/common/components/SelectLanguage.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    vSelect,
    selectLanguage,
  },
  data() {
    return {
      required,
      snowOption: {
        theme: 'snow',
      },
      productsForm: {
        title: '',
        exerpt: '',
        description: '',
        image: '',
        productcategory: '',
        certification: '',
        language_id: '',
      },
      featuredImage: {
        image: null,
        url: null,
      },
      preview: null,
      ValidationErrors: {
        language: '',
      },
      loader: false,
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'productsForm.language_id': function (value) {
      if (value === '') {
        this.ValidationErrors.language = 'Please Select Language'
      } else this.ValidationErrors.language = ''
    },
  },
  mounted() {
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()
    const { formData, setFormData } = handleFormData()

    // Get Categories List
    const fetchCategoriesList = () => store.dispatch('categoriesDetils/getProductCategory')
    fetchCategoriesList()
    const categoriesList = computed(() => store.state.categoriesDetils.productCategory)

    // Get Certifications List
    const fetchCertificationsList = () => store.dispatch('categoriesDetils/getCertificationsCategory')
    fetchCertificationsList()
    const certificationList = computed(() => store.state.categoriesDetils.certifications)

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      formData,
      setFormData,
      categoriesList,
      certificationList,
    }
  },
  methods: {
    onChange(e) {
      // eslint-disable-next-line prefer-destructuring
      this.productsForm.image = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.preview = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    addProduct() {
      this.$refs.infoRules.validate().then(success => {
        if (success) {
          this.loader = true
          this.setFormData(this.productsForm)
          axios.post('add/product', this.formData).then(res => {
            if (res.status === 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Added Successfully',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.$router.push('/products/list')
            }
          }).catch(err => {
            if (err) {
              Object.assign(this.ValidationErrors, err.response.data.errors)
            }
          }).finally(() => {
            this.loader = false
          })
        }
      })
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
